import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question: "How do you help customers determine their exact needs for a product?",
      answer:
        "We meet with you either in person or virtually and compare your needs with current best practices to determine a build and cost timeline.",
    },
    {
      question: "Will we be able to add on features after the project is completed?",
      answer:
        "Any features requested outside of the original build scope will be accepted and built according to contract.",
    },
    {
      question: "What frameworks, functions and features are readily available?",
      answer:
        "SaaS, CMS, Paid Content, POflow, PostFunnel, Landing Pages and more... we never say no."
    },
    {
      question: "Why are you different from cookie cutter options?",
      answer:
        "We provide completely custom development and direct personal attention to the client's needs and preferences.",
    },
    {
      question: "How long do you provide updates and support?",
      answer:
        "Our client project contracts are built based on what they feel they will need, combined with what we know to be common or best in similar situations.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center text-light textshadow"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {item.question}
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;


/*

What customizations are available? How much time will it save them having their own custom system? Why are you different from cookie cutter options? How long do you provide support? How do you help customers determine their exact needs for a product?

Next.js, React.js, Bootstrap, MaterialUI, Auth0, OAuth, Supabase, Firebase, AWS, Firestore, Stripe Payments, Mailchimp, Google Sheets, Amazon SES, Google Analytics, Crisp, Intercom

*/