import React from "react";
import "./SectionHeader.scss";

function SectionSubHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionSubHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
  
      {props.subtitle && (
        <p className="SectionSubHeader__subtitle mb-0">{props.subtitle}</p>
      )}
    </header>
  );
}

export default SectionSubHeader;
