import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import Showcase from "../components/Showcase";
// import ClientsSection from "./../components/ClientsSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
// import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bg=""
        textColor="light"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Modern Websites '&' Custom Software Solutions"
        subtitle="One size does NOT fit all. We have the power to rescue you from mediocre prebuilt software solutions."
        buttonText="Get Started"
        buttonColor="danger"
        buttonPath="/contact"
      />
      <FeaturesSection
        bg=""
        textColor="white"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Custom Software"
        subtitle="In today's dynamic business landscape, the shift away from one-size-fits-all software suites towards custom solutions is unmistakable. KickApps.io leads this transformation, offering bespoke software tailored to each enterprise's unique needs.

        Custom software development addresses the shortcomings of off-the-shelf solutions, offering scalability, security, and competitive differentiation. Unlike pre-packaged software, custom solutions from KickApps.io scale seamlessly, adapt to evolving needs, and enhance security with personalized protocols.
        
        Moreover, custom software provides a competitive edge by fostering innovation and differentiation. While off-the-shelf software offers little in terms of uniqueness, custom solutions empower businesses to innovate, deliver personalized experiences, and stand out in the market.
        
        Additionally, custom software enhances agility, enabling businesses to adapt quickly to changing environments and seize opportunities. Unlike rigid off-the-shelf solutions, custom software from KickApps.io facilitates continuous iteration and innovation, ensuring businesses stay ahead of the curve.
        
        In conclusion, KickApps.io's custom software solutions offer businesses the means to unlock their full potential, driving efficiency, security, competitiveness, and agility in today's digital economy. Embracing custom software is not just a choice; it's a strategic imperative for businesses looking to thrive in an ever-evolving landscape."
      />
      {/* <ClientsSection
        bg="light"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="You're in good company"
        subtitle=""
      /> */}
      {/* <TestimonialsSection
        bg=""
        textColor="tirtiary"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Word on the Street"
        subtitle=""
      /> */}

      <Showcase
        bg=""
        textColor="white"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Our Tailored Software"
        subtitle="Streamlined software to conquer problems and overhead waste at the same time."
      />
      {/* <CtaSection
        bg=""
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="warning"
        buttonPath="/contact"
      /> */}
      <NewsletterSection
        bg=""
        textColor="pink"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="dark"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default IndexPage;
