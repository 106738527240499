import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";

function TeamBiosSection(props) {
  const items = [
    {
      avatar: "/images/gitbrad.jpg",
      name: "Brad Johnston",
      role: "CEO",
      list1: "Company Founder",
      list2: "Full Stack Certified",
      list3: "UI | UX Design and Development",
      list4: "Frontend Developer",
      list5: "React, JSX, HTML5, CSS3",
      list6: "Illustrator, Photoshop, InDesign, XD",
      list7: "Progressive Web Applications Specialist",
      list8: "Design, Development, & Deployment",
      bio: "After graduating the Full Stack developer course at UCR Riverside I am now a Full Stack developer with Front End tendencies. In the Lead UI/UX designer role, I am never one to stop learning or creating. As a motivated self-starter I am a founder to the core. Always a team player and natural leader, I graduated the Leadership Dekalb Class of 2010. I strive to give the client AND the user what they want.",

    },
    // {
    //   avatar: "/images/gitjustin.jpg",
    //   name: "Justin Moore",
    //   role: "CTO",
    //   list1: "Company Founder",
    //   list2: "Software Server and Database Design",
    //   list3: "Full Stack Certified",
    //   list4: "Backend Developer",
    //   list5: "JavaScript, Node, Express, React",
    //   list6: "AWS Experience",
    //   list7: "Object Oriented Programming",
    //   list8: "Product Development",
    //   bio: "After completing a Web Development program through Denver University, I was immediately recruited into a TA position. During my time as a TA, I have filled many roles from tutoring to substitute teaching, and observed web development from numerous viewpoints. My history of educating new and seasoned programmers alike has given me the necessary insight to ensure all KICKapps products are intuitive, regardless the complexity of the job needing done.",

    // },
    // {
    //   avatar: "/images/gitderek.jpg",
    //   name: "Derek Minney",
    //   role: "COO",
    //   list1: "Company Founder",
    //   list2: "MBA",
    //   list3: "Customer Relationship Management Experience",
    //   list4: "Full Stack Certified",
    //   list5: "MySQL, MongoDB, MariaDB",
    //   list6: "Secure Financial Database Design",
    //   list7: "Credit card billing database specialist",
    //   list8: "Product Deployment",
    //   bio: "After earning a business degree with a concentration in marketing, I set my sites on obtaining multiple programming certifications. This education, partnered with a vast work experience for some of the most recognizable brands in the world, has given me the insight necessary to ensure the clients and users alike retain a tailor made product, built to their specifications.",

    // },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center text-light textshadow"
        />
        <Row className="justify-content-center">
          <Card>
            <Card.Body className="d-flex flex-column text-center align-items-center">
              <div class="container">

                <div class="header">
                  <h1>Transform Your Online Presence</h1>
                  <p>and Streamline Your Operations</p>
                </div>

                <div class="content">
                  <p>At <strong>KickApps</strong>, we specialize in helping businesses like yours enhance their digital presence with custom website solutions that not only look great but also improve user experience and drive engagement.</p>

                  <h3>Why a Website Refresh Can Benefit Your Business:</h3>
                  <ul>
                    <li><strong>Improved User Experience:</strong> A fresh, responsive design can make your website more accessible and engaging for your audience, increasing time spent on the site and improving conversion rates.</li>
                    <li><strong>Optimized for Mobile:</strong> A mobile-first approach ensures a seamless experience across all devices.</li>
                    <li><strong>Enhanced Credibility:</strong> A modern website reflects the professionalism and innovation of your brand, helping build trust with both current and potential customers.</li>
                    <li><strong>SEO Optimization:</strong> Our websites are built with search engine optimization in mind, driving organic traffic and increasing visibility in your industry.</li>
                  </ul>

                  <h3>Custom Software to Streamline Your Operations:</h3>
                  <p>Beyond the website, we understand that internal efficiency is crucial to scaling your business. That’s why we also develop <strong>custom software solutions</strong> to automate routine tasks, integrate data, and improve workflow efficiency. Whether it's a CRM, inventory management, or a bespoke system unique to Your Business, we tailor solutions that fit your exact needs.</p>

                  <h3>Why KickApps?</h3>
                  <ul>
                    <li><strong>Expert Team:</strong> Our developers have years of experience building cutting-edge websites and software.</li>
                    <li><strong>Tailored Approach:</strong> We focus on understanding your business goals and delivering solutions that directly support your vision.</li>
                    <li><strong>Long-Term Partnership:</strong> We provide ongoing support, ensuring your digital assets continue to perform optimally and grow with your business.</li>
                  </ul>

                  <p>We’d love to chat about how we can work together to give Your Business the online presence and internal tools it needs to thrive in today’s market.</p>

                  <Col xs={12} md="auto" className="px-4">
                    <LinkContainer to="https://calendly.com/therealkickapps/30min">
                      <Button variant="primary" size="lg" className="text-white">
                        Book a Consultation
                      </Button>
                    </LinkContainer>
                  </Col>
                </div>



              </div>
            </Card.Body>
          </Card>
        </Row>
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              xs={12}
              md={6}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              {/* <Card className="bg-whitelite">
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <Avatar src={item.avatar} alt={item.name} size="128px" />
                  <h6 className="font-weight-bold mb-0 mt-4">{item.name}</h6>
                  <small>{item.role}</small>
                  <Card.Text className="mt-1">{item.list1}</Card.Text>
                  <Card.Text className="mt-1">{item.list2}</Card.Text>
                  <Card.Text className="mt-1">{item.list3}</Card.Text>
                  <Card.Text className="mt-1">{item.list4}</Card.Text>
                  <Card.Text className="mt-1">{item.list5}</Card.Text>
                  <Card.Text className="mt-1">{item.list6}</Card.Text>
                  <Card.Text className="mt-1">{item.list7}</Card.Text>
                  <Card.Text className="mt-1">{item.list8}</Card.Text>
                  <Card.Text className="mt-4">{item.bio}</Card.Text>
                </Card.Body>
              </Card> */}
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
