import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import "./PricingSection.scss";

function PricingSection(props) {
  const auth = useAuth();

  const plans = [
    
    {
      id: "pro",
      name: "Pro Support Billing Setup",
      price: "199",
      perks: [
        "24/7 Help and Support",
        "AWS Infrastructure and Uptime",
        "Domain Registration (if applicable)",
        "Hosting (if applicable)",
      ],
    },
    
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center text-light textshadow"
        />
        <Row className="justify-content-center">
          {plans.map((plan, index) => (
            <Col
              md={12}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="w-100 bg-whitelite">
                <Card.Body className="d-flex flex-column p-4 text-dark">
                  <h5 className="font-weight-bold mb-4">{plan.name}</h5>
                  <h1 className="font-weight-bold mb-3">
                    ${plan.price}
                    <small className="PricingSection__period">/mo</small>
                  </h1>

                  {plan.description && (
                    <Card.Text className="mb-4">{plan.description}</Card.Text>
                  )}

                  {plan.perks && (
                    <Card.Text as="span" className="mt-2 mb-3">
                      <ul className="list-unstyled">
                        {plan.perks.map((perk, index) => (
                          <li className="py-1" key={index}>
                            <i className="fas fa-check text-success mr-3" />
                            {perk}
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  )}

                  <LinkContainer
                    to=
                    {
                      
                      auth.user
                        ? `/purchase/${plan.id}`
                        : `/auth/signup?next=/purchase/${plan.id}`
                    }
                  >
                    <Button
                      variant="primary"
                      size="lg"
                      block={true}
                      className="mt-auto"
                    >
                      Choose
                    </Button>
                  </LinkContainer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
