import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import CtaSection from "./../components/CtaSection";
import "./FeaturesSection.scss";

function Showcase(props) {
  const items = [
    {
      title: "",
      body: "KICKapps has developed POflow, a paperless purchase order system designed to keep all levels of approval and authorization digital until the PO is completed and needs to be emailed or at worst, printed.",
      image: "/images/poflow/poflowlogo.png",
      image2: "/images/poflow/shot1.png",
    },
    // {
    //   title: "Need Guidance?",
    //   body: "We help you figure out what you need and create a precision built solution that grows with you.",
    //   image: "/images/directions.svg",
    // },
    // {
    //   title: "Want Flexibility?",
    //   body: "Software suites can feel bloated. A tailored solution can finally free you from mainstream offerings.",
    //   image: "/images/stability.svg",
    // },
  
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      
    >
      <Container className="text-center">
       
        <div className="ghost-card-blk">
        <br></br>
        <br></br>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          
        />
          <Row className="no-gutters overflow-hidden">
            {items.map((item, index) => (
             
              <Col
                xs={12}
                lg={12}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                }}
                key={index}
              >
                <div className="FeaturesSection__item has-text-centered">
                  <div className="FeaturesSection__image-container">
                    <AspectRatio ratio={4 / 3}>
                      <Image src={item.image} alt={item.title} fluid={true} />
                    </AspectRatio>
                      
                  </div>
                  <div className="col">
                      <Image src={item.image2} alt={item.title2} fluid={true} />
                      </div>
                  <h4>{item.title}</h4>
                  <p>{item.body}</p>
                  <div>
                          
                  <CtaSection
                    bg=""
                    textColor="light"
                    size="md"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Get Started Today"
                    subtitle=""
                    buttonText="Schedule a Demo"
                    buttonColor="warning"
                    buttonPath="/contact"
                    />
                  </div>

                </div>
              </Col>
        
             
            ))}
            

          </Row>
          
        </div>
      </Container>
    </Section>
  );
}

export default Showcase;
