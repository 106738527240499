import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
        <div className="row">
          <div className='glass-card-teal ml-auto my-auto mr-auto col-lg-5 col-10 shadow-lg border mt-5'>
                    
            {/* <h4 className="col center pt-5 mt-5">
              <p className="center text-light">Email us at:&nbsp;&nbsp;</p>
              <a className="center text-light" href="mailto:info@kickapps.com">info@kickapps.io</a>
            </h4> */}
            
            <h2 className="col txt-prime center mt-5 mb-5">
              <p className="center text-light">Call us at:&nbsp;&nbsp;</p>
              <a className="center text-light" href="tel:951-933-9085">951.933.9085</a>
              <br/>
              <small className="center text-dark">weekdays 8a-5p est</small>
            </h2>

          </div>
          <div className="col-lg-5 col-10 m-auto">
            <ContactSection
              bg="transparent"
              textColor="light"
              size="md"
              bgImage=""
              bgImageOpacity={1}
              title="Contact Us"
              subtitle=""
              buttonText="Send message"
              buttonColor="primary"
              showNameField={true}
            />
          </div>
          </div>
    </>
  );
}

export default ContactPage;
