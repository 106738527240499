import React from "react";
import Meta from "../components/Meta";
import HeroSection2 from "../components/HeroSection2";


function Paperless(props) {
  return (
    <>
      <Meta title="Paperless" description="KICKapps pledges to develop software that takes the place of paper in the workplace." />
      <HeroSection2
        bg="primarylite"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="KICKapps pledges to develop software that takes the place of paper in the workplace."
        subtitle="On average, a typical American office worker goes through as many as 10,000 sheets of copy paper every year. 
        It's an astounding number that results in even more astounding costs. According to an estimate by Record Nations, per year, 
        an average business can spend as much as: $8,000 on paper alone. Paper accounts for 25 percent of waste in landfill and 33 percent of municipal waste. 
        About 68 million trees are cut down each year to produce paper and paper products. If you don't recycle the paper you use, it all ends up in the landfill. 
        KICKapps develops software that takes the place of paper in the workplace. We are commited to intercepting these markets and dialing back the paper waste one project and one client at a time."
      />
     
    </>
  );
}

export default Paperless;
