import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";


function PortfolioSection(props) {
  const items = [
    
    {
      name: "DCschools",
      image: "./images/dcschools.png",
      width: "96%",
      brandname: "POflow",
      brandimage: "./images/dcschoolslogo.png",
      brandwidth: "90%",
      link: "./contact",
    },
    
    {
      name: "SoulfullyYou",
      image: "./images/soulful.png",
      width: "90%",
      brandname: "POflow",
      brandimage: "./images/chanelray.png",
      brandwidth: "90%",
      link: "https://authorchanelray.com",
    },
    {
      name: "DCHealthfit",
      image: "./images/dchealthpic.png",
      width: "90%",
      brandname: "POflow",
      brandimage: "./images/dchealthlogo.gif",
      brandwidth: "30%",
      link: "https://dchealthfit.com",
    },
    {
      name: "Echo8Safety",
      image: "./images/echo8pic.png",
      width: "95%",
      brandname: "POflow",
      brandimage: "./images/echo8logo.svg",
      brandwidth: "60%",
      link: "./contact",
    },
    {
      name: "POflow",
      image: "./images/poflow/shot2.png",
      width: "100%",
      brandname: "POflow",
      brandimage: "./images/poflow/poflowlogo200.png",
      brandwidth: "90%",
      link: "./contact",
    },
  ];


  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center text-light">
        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="textshadow"
        /> */}
       
        <Row className="justify-content-center">
       
          {items.map((item, index) => (
            <Col md="6" className="py-3 px-4"
             key={index}
             >
              {/* <div className="col-4 center">
                 <img src={item.brandimage} width={item.brandwidth} alt={item.brandname} />
                </div> */}
               <a href={item.link} target="_blank" rel="noreferrer">
                <div className="align-bottom">
                  <img src={item.image} width={item.width} alt={item.name} />
                </div>
               </a>
              
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PortfolioSection;
