import React from "react";
import Meta from "../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import POflowSection from "../components/POflowSection";
// import StatsSection from "../components/StatsSection";
// import TeamBiosSection from "../components/TeamBiosSection";
import CtaSection from "../components/CtaSection";

function POflow(props) {
  return (
    <>
      <Meta title="POflow" description="Supplement your purchase order system with paperless approval flows!" />
      <HeroSection2
        bg="primarylite"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="We can save you time($) and paper($) while helping save the environment on PO at a time."
        subtitle="KICKapps has developed POflow, a paperless purchase order system designed to keep all levels of approval and authorization digital until the PO is completed and needs to be emailed or at worst, printed."
      />
      {/* <StatsSection
        bg="blacklite"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
      /> */}
       <POflowSection
        bg="blacklite"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <CtaSection
        bg="transparent"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="dark"
        buttonPath="/contact"
      />
    </>
  );
}

export default POflow;
