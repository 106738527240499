import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import CtaSection from "./../components/CtaSection";
import "./FeaturesSection.scss";

function FeaturesSection(props) {
  const items = [
    {
      title: "Feeling Trapped?",
      body: "We can free you from cookie-cutter solutions that don't seem to quite fit your business needs.",
      image: "/images/fishbowl.svg",
    },
    {
      title: "Need Guidance?",
      body: "We help you figure out what you need and create a precision built solution that grows with you.",
      image: "/images/directions.svg",
    },
    {
      title: "Want Flexibility?",
      body: "Software suites can feel bloated. A tailored solution can finally free you from mainstream offerings.",
      image: "/images/stability.svg",
    },
  
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container className="text-center">
       
        <div className="glass-card-teal">
        
      
          <Row className="no-gutters overflow-hidden">
            {items.map((item, index) => (
              <Col
                xs={12}
                lg={4}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                }}
                key={index}
              >
                <div className="FeaturesSection__item has-text-centered">
                  <div className="FeaturesSection__image-container">
                    <AspectRatio ratio={4 / 3}>
                      <Image src={item.image} alt={item.title} fluid={true} />
                    </AspectRatio>
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.body}</p>
                </div>
              </Col>
            ))}
          </Row>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
            
          />
          <CtaSection
            bg=""
            textColor="light"
            size="sm"
            bgImage=""
            bgImageOpacity={1}
            title="Ready to get started?"
            subtitle=""
            buttonText="Get Started"
            buttonColor="warning"
            buttonPath="/contact"
          />
        </div>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
