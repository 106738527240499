import React from "react";
import "./../styles/global.scss";
import "./../styles/index.global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
// import Setup from "./setup";
import POflow from "./poflow";
import Paperless from "./paperless";
import Portfolio from "./portfolio";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";




function App(props) {

  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>

            <NavbarCustom
              bg="black"
              variant="dark"
              expand="md"
              logo="/images/logowht320.png"
            />
            <img
              className="cityforeground"
              src="/images/newskyline1lower.svg"
              alt="Logo"
            />
            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/pricing" component={PricingPage} />

              {/* <Route exact path="/setup" component={Setup} /> */}

              <Route exact path="/poflow" component={POflow} />

              <Route exact path="/paperless" component={Paperless} />

              <Route exact path="/portfolio" component={Portfolio} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route exact path="/purchase/:plan" component={PurchasePage} />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="blacklite"
              textColor="light"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              description="Modern Websites & Custom Software Solutions"
              copyright={`© ${new Date().getFullYear()} KICKapps`}
              logo="/images/logoocto.svg"
            />
            <div className="clouds"></div>
            <div className="blackbkg live-gradient"></div>
            <img
              className="citybkg"
              src="/images/newskyline1.svg"
              alt="Logo"
            />

          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
