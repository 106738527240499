import React from "react";
import Meta from "../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import PortfolioSection from "../components/PortfolioSection";
// import StatsSection from "../components/StatsSection";
// import TeamBiosSection from "../components/TeamBiosSection";
import CtaSection from "../components/CtaSection";

function Portfolio(props) {
  return (
    <>
      <Meta title="Portfolio" description="A short list of some of our work." />
      <HeroSection2
        bg="primarylite"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Portfolio"
        subtitle="A short-list of some of our work."
      />
      {/* <StatsSection
        bg="blacklite"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
      /> */}
       <PortfolioSection
        bg="dark"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <CtaSection
        bg="transparent"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="dark"
        buttonPath="/contact"
      />
    </>
  );
}

export default Portfolio;
